import React, { FC, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Provider, useSelector } from 'react-redux'
import { Initialization, Logout } from '@mit/aws-react'
import { HuiThemeProvider } from '@mit/hui-core'
import { useAppInitializer } from 'hooks/Initializer'
import { RootState, store } from 'store'
import appConfig from 'app-config'
import reportWebVitals from './reportWebVitals'
import 'scss/main.scss'
import ReactGA from 'react-ga'

const App = React.lazy(async () => await import('components/app/App').then(module => ({ default: module.App })))

const WrappedApp: FC = () => {
  const { appContact, appDescription, appName } = useSelector((state: RootState) => state.appConfig)
  const { initializeApp } = useAppInitializer()

  useEffect(() => {
    ReactGA.initialize('G-R8E68YJSPK')
    ReactGA.pageview(window.location.pathname)
  }, [])

  return (
    <>
      <Helmet titleTemplate={`${appName} - %s`} defaultTitle={appName}>
        {/* <title>{appName}</title> */}
        <meta name='description' content={appDescription} />
      </Helmet>
      <React.StrictMode>
        <Routes>
          <Route path='/logout' element={<Logout />} />
          <Route
            path='*'
            element={
              <Initialization
                App={App}
                appInitializer={initializeApp}
                appConfig={appConfig}
                useCognitoAuthentication={false}
                loaderProps={{
                  contactEmail: appContact,
                  name: appName,
                  type: 'Default',
                  isLoading: true
                }}
              />
              // <h1>Testing</h1>
            }
          />
          {/* </Route> */}
        </Routes>
      </React.StrictMode>
    </>
  )
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <HuiThemeProvider variant='default'>
        <WrappedApp />
      </HuiThemeProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
